
import { defineComponent, computed } from 'vue'

import StepContainer from '@/components/LdStepper/StepContainer/StepContainer.vue'
import StepHeader from '@/components/LdStepper/StepHeader/StepHeader.vue'
import StepFooter from '@/components/LdStepper/StepFooter/StepFooter.vue'
import { useI18n } from 'vue-i18n'
import { getStoreHash } from '@/lib/utilities'
import { useStore } from 'vuex'
import { Steps } from '@/views/connection/Connection.vue'
import { sendPageViewEvent, sendBehaviorEvent, AppVirtualUrls } from '@/services/segmentTracking'

export default defineComponent({
	components: {
		StepContainer,
		StepHeader,
		StepFooter,
	},
	emits: ['nextPage'],
	setup(props, { emit }) {
		const { tm, t } = useI18n()
		const store = useStore()
		const points = tm('connection.steps.nativeGA.points') as any
		const userData = computed(() => store.getters['user/me/getState'])
		const { webPropertyId } = store.state.connection
		const storeHash = getStoreHash(userData.value.data.store)
		const onNextPage = async () => {
			sendBehaviorEvent({
				locationTitle: t('connection.steps.nativeGA.title'),
				virtualUrl: AppVirtualUrls.disableBcGA,
				fieldTitle: '',
				actionId: '',
				actionValue: t('common.continue'),
				actionType: 'button',
			})

			const { apiSecret, measurementId } = store.state.connection

			// do something with the input data
			// at the end emit a switch step event to switch to the next step
			store.dispatch('connection/properties/updateGAProperties', {
				storeHash,
				webPropertyId: webPropertyId!,
				measurementId,
				apiSecret,
			})
			emit('nextPage', Steps.LOADING)
		}

		return {
			onNextPage,
			points,
			storeHash,
		}
	},
	mounted() {
		const { t } = useI18n()

		sendPageViewEvent({
			locationTitle: t('connection.steps.nativeGA.title'),
			virtualUrl: AppVirtualUrls.disableBcGA,
		})

		const { listItems } = this.$refs as any

		// attach a click event to the first item from the list
		listItems[0].addEventListener('click', () => {
			sendBehaviorEvent({
				locationTitle: t('connection.steps.nativeGA.title'),
				virtualUrl: AppVirtualUrls.disableBcGA,
				fieldTitle: 'BigCommerce Data Solution admin',
				actionId: '',
				actionValue: listItems[0].getElementsByTagName('a')[0].attributes.href.value,
				actionType: 'link',
			})
		})
	},
})
